/*!
 * ================================
 * jQuery Plugins By Eko Mardiatno.
 * instagram @komafx
 * wa.me/6282219299071
 * email: ekomardiatno@gmail.com
 * ================================
 */
if (typeof jQuery === 'undefined') {
  throw new Error('Requires jQuery')
} else {
  console.log(
    'Styles & Plugins by Eko Mardiatno\n'+
    'Instagram @komafx\n'+
    'ekomardiatno@gmail.com'
  )
}